import React from 'react';
// import uztelecom from '../../images/uztelecom.png';
import RegionList from './regionList';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import electionsLogo from '../../images/electionsLogo.png';
import './sidebar.scss';
import { electionsDate, topTitle } from '../../utlis/texts';
import { logos } from '../../utlis/logos';

const Sidebar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.mobileMenu);
  const isTablet = useMediaQuery({ maxWidth: 769 });
  const CLOSE_MENU = 'CLOSE_MENU';
  const lang = useSelector((state) => state.lang);

  return (
    <>
      <div className={`sidebar ${isOpen && isTablet ? 'sidebar-mobile' : ''}`}>
        <div className={'sidebar-content'}>
          {isTablet ? (
            <div className="navbar-section">
              <img src={electionsLogo} alt="elections logo" />
              <div className="navbar-text">
                <h2>{topTitle[lang]}</h2>
                <p>{electionsDate[lang]}</p>
              </div>
            </div>
          ) : (
            <div className="sidebar-logo">
              <Link to="/">
                <img style={{ width: '100%' }} src={logos[lang]} alt="logo" />
              </Link>
            </div>
          )}
          <RegionList
            isTablet={isTablet}
            closeMenu={() =>
              dispatch({
                type: CLOSE_MENU,
              })
            }
          />
        </div>
      </div>
      {isTablet && isOpen && (
        <div
          onClick={() => {
            dispatch({
              type: CLOSE_MENU,
            });
          }}
          className="sidebar-mask"
        />
      )}
    </>
  );
};

export default Sidebar;
