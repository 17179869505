import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

import './player.scss';
// import tempImage from '../../images/sliderImage.png';
import './player.scss';
import 'swiper/swiper.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';

const CameraList = ({ cameras, lang, setSelectedCamera, selectedCamera }) => {
  const isGreater2 = cameras?.data?.length > 2;

  const camerasStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div
      className="cameras"
      id="cameras"
      style={isGreater2 ? undefined : camerasStyles}
    >
      {isGreater2 ? (
        <Swiper
          slidesPerView="auto"
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode]}
        >
          {cameras.data.map((camera, __) => {
            const isSelected =
              selectedCamera && selectedCamera?.video === camera?.video;
            return (
              <SwiperSlide
                key={__}
                style={{ outline: 'none' }}
                className="camera-link"
              >
                <div
                  onClick={(e) => {
                    e.currentTarget.scrollIntoView({
                      block: 'center',
                      behavior: 'smooth',
                      inline: 'center',
                    });
                    setSelectedCamera(camera);
                  }}
                >
                  <div className={`camera ${isSelected ? 'before' : ''}`}>
                    <img src={camera?.img ? camera?.img : ''} alt="thumbnail" />
                    <div
                      className="camera-number"
                      style={{
                        opacity: isSelected ? '1' : undefined,
                      }}
                    >
                      <p>{camera[lang]}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}{' '}
        </Swiper>
      ) : (
        cameras.data.map((camera, __) => {
          return (
            <div
              key={__}
              style={{ outline: 'none' }}
              onClick={() => {
                setSelectedCamera(camera);
              }}
            >
              <div className="camera-link">
                <div className="camera">
                  <img src={camera?.img ? camera?.img : ''} alt="thumbnail" />
                  <div className="camera-number">
                    <p>{camera[lang]}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CameraList;
