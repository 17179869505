const CHANGE_LANG = 'CHANGE_LANG';

const langFromStorage = localStorage.getItem('lang');
const langs = ['uz', 'ru', 'en'];

const initialState = langs.includes(langFromStorage) ? langFromStorage : 'uz';

const langReducer = (state = initialState, action) => {
  if (action.type === CHANGE_LANG) {
    return action.payload || 'uz';
  }

  return state;
};

export default langReducer;
