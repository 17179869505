import axios from 'axios';

const baseURL = 'https://elect.uzcloud.uz/';

const API = axios.create({
  baseURL,
  responseType: 'json',
});

export default API;
