import React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import menuIcon from '../../images/menuIcon.png';
import electionsLogo from '../../images/electionsLogo.png';
import './navbar.scss';

import { useSelector } from 'react-redux';
import { electionsDate, topTitle } from '../../utlis/texts';

import { logos } from '../../utlis/logos';

const Navbar = () => {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ maxWidth: 890 });
  const lang = useSelector((state) => state.lang);
  const SHOW_MENU = 'SHOW_MENU';
  // const changeLang = 'CHANGE_LANG';
  // const [open, setOpen] = useState(false);
  // const langsList = (
  //   <ul className="navbar-langs">
  //     <li
  //       className={`navbar-langs__item ${
  //         lang === 'uz' ? 'navbar-langs__active' : ''
  //       }`}
  //       onClick={() => {
  //         dispatch({
  //           type: changeLang,
  //           payload: 'uz',
  //         });
  //         setOpen(false);
  //       }}
  //     >
  //       O’zbekcha
  //     </li>
  //   </ul>
  // );

  return (
    <div className="navbar">
      <div className="container">
        {isTablet ? (
          <div className="navbar-items">
            <div
              onClick={() => {
                dispatch({
                  type: SHOW_MENU,
                });
              }}
            >
              <img
                style={{ width: '35px', height: '35px' }}
                src={menuIcon}
                alt="menu icon"
              />
            </div>
            <Link to="/">
              <div>
                <img style={{ width: '200px' }} src={logos[lang]} alt="logo" />
              </div>
            </Link>
            <div />
          </div>
        ) : null}
        {isTablet ? null : (
          <div className="navbar-section">
            <div className="navbar-textWrapper">
              <img src={electionsLogo} alt="elections logo" />
              <div className="navbar-text">
                <h2>{topTitle[lang]}</h2>
                <p>{electionsDate[lang]}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
