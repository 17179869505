import Sidebar from './components/sidebar';
import Navbar from './components/navbar';
import Player from './components/player';
// import bgTop from './images/bgTop.svg';
// import bgMiddle from './images/bgMiddle.svg';
// import bgBottom from './images/bgBottom.svg';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Sidebar />
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Player />
            </Route>
            <Route path="/:region/:id">
              <Player />
            </Route>
          </Switch>
        </div>
        {/* <div className="bgTop">
          <img src={bgTop} alt="referendum 2021" />
        </div> */}
      </Router>
    </div>
  );
};

export default App;
