export const findLang = (lang) => {
  switch (lang) {
    case 'uz':
      return "O'zbekcha";
    case 'ru':
      return 'Русский';
    case 'en':
      return 'English';
    default:
      return "O'zbekcha";
  }
};


export const findShortLang = (lang) => {
  switch (lang) {
    case 'uz':
      return "O'z";
    case 'ru':
      return 'Ру';
    case 'en':
      return 'En';
    default:
      return "O'z";
  }
};

export const findError = (lang) => {
  switch (lang) {
    case 'uz':
      return 'Tizimda xatolik.';
    case 'ru':
      return 'Системная ошибка.';
    case 'en':
      return 'System error.';
    default:
      return 'Tizimda xatolik.';
  }
};


export const getDaysUntilElections = () => {
  const now = new Date().getTime();
  const countDownDate = new Date('2021-10-24').getTime();
  const distance = countDownDate - now;
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  if (days === 0 && hours > 0) {
    return 1;
  }

  return days;
};
