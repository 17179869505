export const topTitle = {
  ru: 'ОНЛАЙН-МОНИТОРИНГ ЗА РЕФЕРЕНДУМОМ В РЕСПУБЛИКЕ УЗБЕКИСТАН',
  uz: 'O‘ZBEKISTON RESPUBLIKASI PREZIDENTI SAYLOVI ONLAYN MONITORINGI',
  en: 'ONLINE MONITORING OF THE REFERENDUM OF THE REPUBLIC OF UZBEKISTAN',
};

export const electionsDate = {
  uz: '2023-yil, 9-iyul, Yakshanba',
  ru: '',
  en: '',
};

const findRussianDay = (day) => {
  switch (day) {
    case 1:
      return 'день';
    case 2:
    case 3:
    case 4:
      return 'дня';
    default:
      return 'дней';
  }
};

export const daysUntilElections = (days, lang) => {
  switch (lang) {
    case 'uz':
      return (
        <>
          referendum boshlanishiga
          <br />
          <span>{days}</span> kun qoldi
        </>
      );
    case 'ru':
      return (
        <>
          до начала выборов
          <br />
          осталось <span>{days}</span> {findRussianDay(Number(days))}
        </>
      );
    case 'en':
      return (
        <>
          <span>{days}</span> days left
          <br />
          before the elections
        </>
      );
    default:
      return;
  }
};
